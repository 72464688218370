import { MakeEnvironment } from '@shared/interfaces/make-environment';

export const makeEnvironment = (
  ENV_PATH: string,
  production: boolean,
  GOOGLE_ANALYTICS: string,
  TAG_MANAGER: string,
  AMBIENTE: string,
  LANDING_PAGE: string
): MakeEnvironment => ({
  VERSION: '0.0.1',
  production,
  AMBIENTE,
  ADMISSIONS: `${ENV_PATH}/admissions/person/`,
  GOOGLE_ANALYTICS,
  TAG_MANAGER,
  ADDRESS: `${ENV_PATH}/address/cep/`,
  IBGE_API: 'https://servicodados.ibge.gov.br/api/v1',
  BRASIL_API: 'https://brasilapi.com.br/api',
  API_TOKEN: '2ab781a668785052c2d3424da4601a4240bccb772a57087813e82928c63c7741',
  ESFERA_EMAIL: 'gd@esferaenergia.com.br',
  ESFERA_WHATSAPP: '+551130144635',
  ESFERA_FACEBOOK: 'https://www.facebook.com/esferagestaodeenergia/?locale=pt_BR',
  ESFERA_INSTAGRAM: 'https://www.instagram.com/esferaenergia/',
  ESFERA_YOUTUBE: 'https://www.youtube.com/channel/UCBuYVcgaT8JztAdeQhexmRw',
  ESFERA_LINKEDIN: 'https://www.linkedin.com/company/esfera-energia-consultoria-e-gest%C3%A3o-de-energia-ltda/',
  LANDING_PAGE,
  SSO_ID: '1036201709493-4mes6bnfpkg64scims4qn18na2j9491p.apps.googleusercontent.com'
});
