import { makeEnvironment } from './make-environment';

const URL = 'https://api-gd.esferaenergia.com.br';
const TERM_URL = 'https://esferaenergia.com.br/EsferaGD/Termo-de-Uso';
const PRIVACY_POLICY_URL = 'https://esferaenergia.com.br/geracaodistribuida/politica-de-privacidade-gd';
const BUSINESS_RELATIONSHIP = 'https://esferaenergia.com.br/EsferaGD/Termo-de-Relação-Comercial';

export const environment = {
  URL,
  TERM_URL,
  PRIVACY_POLICY_URL,
  BUSINESS_RELATIONSHIP,
  ...makeEnvironment(URL, true, 'UA-68961922-1', 'GTM-MQ5B3MR', 'prod', 'https://esferaenergia.com.br/geracaodistribuida')
};
