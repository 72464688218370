import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('../app/modules/onboarding-customer/onboarding-customer.module').then((m) => m.OnboardingCustomerModule)
  },
  {
    path: 'comercial',
    loadChildren: () =>
      import('../app/modules/commercial-onboarding/commercial-onboarding.module').then(
        (m) => m.CommercialOnboardingModule
      )
  },
  {
    path: 'invoice',
    loadChildren: () => import('../app/modules/invoice/invoice.module').then((m) => m.InvoiceModule)
  },
  {
    path: 'download',
    loadChildren: () => import('../app/modules/download/download.module').then((m) => m.DownloadModule)
  },
  {
    path: 'simulador',
    loadChildren: () => import('../app/modules/simulation/simulation.module').then((m) => m.SimulationModule)
  },
  {
    path: 'infografico',
    loadChildren: () => import('../app/modules/infographic/infographic.module').then((m) => m.InfographicModule)
  },
  {
    path: 'outstanding-invoices',
    loadChildren: () =>
      import('../app/modules/outstanding-invoices/outstanding-invoices.module').then((m) => m.OutstandingInvoicesModule)
  },
  {
    path: 'term-acceptance',
    loadChildren: () =>
      import('../app/modules/term-acceptance/term-acceptance.module').then((m) => m.TermAcceptanceModule)
  },
  {
    path: 'credenciais-usuario',
    loadChildren: () =>
      import('../app/modules/user-credentials/user-credentials.module').then((m) => m.UserCredentialsModule)
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
