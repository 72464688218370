import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { AnalyticService } from '@esferaenergia/esfera-commons';
import { takeUntil, filter, Observable, ReplaySubject, BehaviorSubject, tap, delay } from 'rxjs';
import { environment } from '@environments/environment';
import { LoadingService } from '@shared/services/loading/loading.service';

declare var gtag: Function;

@Component({
  selector: 'gd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  private destroy$ = new ReplaySubject(1);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private path = './assets/svg';

  loading$ = this.loadingSubject.asObservable();

  constructor(
    private iconRegistry: MatIconRegistry,
    private analyticService: AnalyticService,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private loadingService: LoadingService
  ) {
    this.loadingService.setLoading(true);
    this.iconRegistry
      .addSvgIcon('gd_arrow-green', this.setPath(`${this.path}/arrow-green.svg`))
      .addSvgIcon('gd_co2', this.setPath(`${this.path}/co2.svg`))
      .addSvgIcon('gd_money', this.setPath(`${this.path}/money.svg`))
      .addSvgIcon('gd_three', this.setPath(`${this.path}/three.svg`))
      .addSvgIcon('gd_car', this.setPath(`${this.path}/car.svg`))
      .addSvgIcon('gd_car-side', this.setPath(`${this.path}/car-side.svg`))
      .addSvgIcon('gd_tree', this.setPath(`${this.path}/tree.svg`))
      .addSvgIcon('gd_bovine', this.setPath(`${this.path}/bovine.svg`));
  }

  ngOnInit(): void {

    if (environment.TAG_MANAGER) this.analyticService.initTagManager(environment.TAG_MANAGER);
    
    if (environment.GOOGLE_ANALYTICS) {
      if(environment.GOOGLE_ANALYTICS) this.analyticService.initGoogleAnalytics(environment.GOOGLE_ANALYTICS);
      this.sendNavigationEventToGA();
    }

    this.forceScrollTopOnNavigate();
  }

  ngAfterViewInit(): void {
    this.listenToLoading();
  }

  ngOnDestroy(): void {
    this.destroy$.next(1);
    this.destroy$.complete();
  }

  sendNavigationEventToGA(): void {
    const navigationEnd = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ) as Observable<NavigationEnd>;
    navigationEnd.subscribe({
      next: (event) => {
        try {
          gtag('config', environment.GOOGLE_ANALYTICS, {
            page_path: event.urlAfterRedirects
          });
        } catch (e) {
          console.error(e);
        }
      }
    });
  }

  setPath(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  listenToLoading(): void {
    this.loadingService.loadingSub
      .pipe(
        takeUntil(this.destroy$),
        delay(0),
        tap((loading: boolean) => this.loadingSubject.next(loading))
      )
      .subscribe();
  }

  private forceScrollTopOnNavigate() {
    this.router.events.subscribe((e) => {
      if(e instanceof NavigationEnd) {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
      }
    });
  }

}
