import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  private pageAccessMethod$ = new BehaviorSubject<string>('')

  setPageAccessMethod(value: string ) {
    this.pageAccessMethod$.next(value)
  }

  getPageAccessMethod() {
    return this.pageAccessMethod$.asObservable()
  }
}
